import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';


import React from "react";

import Routing from "./routing";
import Loader from './Components/Loader';



function App() {
    const [loading, setLoading] = React.useState(true);
    const handleLoading = () => {
        setLoading(false);
    };
    setTimeout(handleLoading, 2500);
    return <div className="App">{loading ? <Loader /> : <Routing />}</div>;
}

export default App;
