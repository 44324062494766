import React from 'react';
import './Footer.scss'; // make sure the path to your SCSS file is correct

const Footer = () => {
 return (

  <footer className="footer bg-light text-center text-lg-start footer-border">

   <div className="container p-4">
    <div className="row">
     <div className="col-lg-4 col-md-6 mb-4 mb-md-0 text-center">
      <h5 className="text-uppercase">Phone</h5>
      <p>+45 71469765</p>
     </div>

     <div className="col-lg-4 col-md-6 mb-4 mb-md-0 text-center">
      <h5 className="text-uppercase">Email</h5>
      <p>mrjewel837@gmail.com</p>
     </div>

     <div className="col-lg-4 col-md-12 mb-4 mb-md-0 text-center">
      <div className='text-center'>
       <h5 className="text-uppercase text-center">Follow Me</h5>
      </div>

      <div className="text-center">
       <a href="https://github.com/yourusername" className="me-3 text-reset">
        <i className="fab fa-github"></i>
       </a>
       <a href="https://linkedin.com/in/yourusername" className="me-3 text-reset">
        <i className="fab fa-linkedin"></i>
       </a>
       <a href="https://linkedin.com/in/yourusername" className="text-reset">
        <i className="fab fa-instagram"></i>
       </a>
      </div>
     </div>
    </div>
   </div>

   <div className="text-center p-3 text-center" style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
    © 2023 By Mahamudur Rahman Jewel
   </div>
  </footer>

 );
}

export default Footer;
