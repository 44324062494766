import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import DropdownNavbar from '../../Components/DropdownNavbar';
import Navbar from '../../Components/Navbar';


const Portfolio = () => {


 const [toggle, setToggle] = React.useState(true);
 const handleToggle = () => {
  setToggle(!toggle);
 };



 return (
  <>
   <DropdownNavbar onclick={handleToggle} toggle={toggle} />
   <Navbar click={handleToggle} header={"My Portfolio"} />
   <div className="container text-start font-s">
    <h1 className="my-5 text-muted">{""}</h1>
    <div className="row gy-4">
     <div className="col-12 col-md-5 mx-auto">
      <h4 className="card-title">Work Experience</h4>
     </div>
     <div className="col-12 col-md-2 mx-auto">
      <p className="card-subtitle mb-2 text-muted">March 2023 - present</p>
     </div>
     <div className="col-12 col-md-5 mx-auto">
      <div>

       <h5 className="card-subtitle mb-2">Full-stack Developer at Phasetree </h5>
       <p className="card-text text-muted">

        As a Full Stack Developer, I combine front-end and back-end expertise to develop web applications for computational material science simulations.
        My work involves crafting responsive interfaces and robust server-side processes to facilitate complex simulations, supporting in material science advancements.
        This role requires a deep understanding of web technologies, enabling me to deliver solutions that streamline research and innovation in the field.
       </p>

      </div>
     </div>

     {/* second col */}
     <div className="col-12 col-md-5 mx-auto" >
      <h2 className="card-title">{""}</h2>
     </div>
     <div className="col-12 col-md-2 mx-auto">
      <p className="card-subtitle mb-2 text-muted">December 2022 - February 2023</p>
     </div>
     <div className="col-12 col-md-5 mx-auto">
      <div>

       <h5 className="card-subtitle mb-2">Frontend Developer at Walor</h5>
       <p className="card-text text-muted">
        As a Frontend Developer at Walor, I focused on creating intuitive user interfaces and analyzing user behavior to enhance user experience.
        Leveraging technologies like React, Redux, and Mixpanel, I implemented features that ensured the platform was not only accessible but also engaging for users reporting sensitive information.
        My role involved closely monitoring user interactions through Mixpanel analytics, allowing us to make data-driven decisions to refine the UI/UX. This work was crucial in building a secure, user-friendly environment that encouraged transparency and trust among users.
       </p>

      </div>
     </div>
     {/* third - col */}
     <div className="col-12 col-md-5 mx-auto" >
      <h2 className="card-title">{""}</h2>
     </div>
     <div className="col-12 col-md-2 mx-auto">
      <p className="card-subtitle mb-2 text-muted">June 2021 - September 2022</p>
     </div>
     <div className="col-12 col-md-5 mx-auto">
      <div>

       <h5 className="card-subtitle mb-2">Freelance Web Developer (Upwork, Fiverr)</h5>
       <p className="card-text text-muted">
        As a freelance web developer, I have had the privilege of working with a diverse range of clients,
        from individuals clients to businesses aiming to enhance their online presence.
        My expertise spans across the core technologies of the web, including HTML, CSS, JavaScript, and React. This technical
        foundation has enabled me to deliver responsive, dynamic, and user-friendly websites tailored to meet the specific needs and goals of each client.
       </p>

      </div>
     </div>

     {/* edu first col */}
     <span className='mt-5 border'>{""}</span>
     <div className="col-12 col-md-5 mx-auto" >
      <h4 className="card-title"> Education </h4>
     </div>
     <div className="col-12 col-md-2 mx-auto">
      <p className="card-subtitle mb-2 text-muted">January 2022 - June 2022</p>
     </div>
     <div className="col-12 col-md-5 mx-auto">
      <div>

       <h5 className="card-subtitle mb-2">Full-stack Developemt Training Program </h5>
       <p className="card-text text-muted">
        I completed a six-month Full Stack Development program that included practical work with development teams from companies in Germany and Finland.
        We tackled different coding challenges and learned best practices, improving my skills in both front-end and back-end development.
        This experience gave me valuable insights into how development teams work and prepared me for handling complex projects more effectively.
       </p>

      </div>
     </div>
     {/* edu second col */}
     <div className="col-12 col-md-5 mx-auto" >
      <h2 className="card-title">{""}</h2>
     </div>
     <div className="col-12 col-md-2 mx-auto">
      <p className="card-subtitle mb-2 text-muted">September 2019 - June 2021</p>
     </div>
     <div className="col-12 col-md-5 mx-auto">
      <div>

       <h5 className="card-subtitle mb-2">MSc in Business Administration and Leadership</h5>
       <p className="card-text text-muted">
        I completed my Master's in Business Administration and Leadership from Roskilde University, Denmark. The program focused on strategic management, leadership, and innovation, fostering my skills in leading and managing change effectively.
        It emphasized practical learning and teamwork, preparing me for leadership roles in the modern business landscape.
       </p>

      </div>
     </div>

     {/* edu third col */}
     <div className="col-12 col-md-5 mx-auto" >
      <h2 className="card-title">{""}</h2>
     </div>
     <div className="col-12 col-md-2 mx-auto">
      <p className="card-subtitle mb-2 text-muted">January 2014 - November 2018</p>
     </div>
     <div className="col-12 col-md-5 mx-auto">
      <div>

       <h5 className="card-subtitle mb-2">BSc in Information Technology Management at National University of Bangladesh</h5>
       <p className="card-text text-muted">
        I obtained my Bachelor's degree in Information Technology Management, where I gained a solid foundation in managing IT resources, project management, and understanding the strategic role of technology in business.
        This program emphasized the integration of IT solutions with business objectives, equipping me with the skills to optimize operations and drive innovation within organizations.
       </p>

      </div>
     </div>

     {/* test  */}

     <span className='mt-5 border'>{""}</span>
     <div className="col-12 col-md-5 mx-auto" >
      <h4 className="card-title"> Personal Project </h4>
     </div>
     <div className="col-12 col-md-2 mx-auto">
      <p className="card-subtitle mb-2 text-muted"></p>
     </div>
     <div className="col-12 col-md-5 mx-auto">
      <div>

       <h5 className="card-subtitle mb-2"> React Nifty Hooks</h5>
       <p className="card-text text-muted">
        React Nifty Hooks is a precision-crafted npm package to empower React developers to build more efficient and cleaner React applications.
        Licensed under MIT, The library offers a variety of hooks that address common functionalities and challenges in React development, saving developer's time and effort.
        <div></div>
        <span></span>
        <a style={{ textDecoration: "none" }} href="https://www.npmjs.com/package/react-nifty-hooks">Explore React Nifty Hooks on NPM</a>
       </p>

      </div>
     </div>

    </div>
   </div>
  </>
 );
};

export default Portfolio;
