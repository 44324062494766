import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Navbar.scss';
import { BiMenu } from "react-icons/bi";

const Navbar = ({ click, header }) => {
 return (
  <nav className="navbar navbar-expand-lg navbar-light bg-white">
   <div className="container-fluid">
    <a className="navbar-brand" href="/">
     <span className="dot"></span> <span>{header}</span>
    </a>
    <button className="navbar-toggler" type="button" onClick={click}>
     <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarNav">
     <ul className="navbar-nav ms-auto">
      <li className="nav-item">
       <BiMenu size={30} onClick={click} style={{ cursor: "pointer" }} />
      </li>
     </ul>
    </div>
   </div>
  </nav>
 );
};

export default Navbar;
