import React, { useState } from "react";
import "./Contact.scss";
import DropdownNavbar from "../../Components/DropdownNavbar";
import Navbar from "../../Components/Navbar";

import toast, { Toaster } from "react-hot-toast";

const Contact = () => {

	const [toggle, setToggle] = React.useState(true);
	const handleToggle = () => {
		setToggle(!toggle);
	};


	const [formData, setFormData] = useState({
		"firstName": "",
		"lastName": "",
		"email": "",
		"subject": "",
		"message": ""
	})

	const handleSubmit = (e) => {
		e.preventDefault();
		setFormData({
			"firstName": "",
			"lastName": "",
			"email": "",
			"subject": "",
			"message": ""
		})
		toast.success("Thanks for reaching out to me. I will get back to you soon")
	}

	return (
		<>
			<Toaster />
			<DropdownNavbar onclick={handleToggle} toggle={toggle} />
			<Navbar click={handleToggle} header={"Get in Touch"} />


			<div className="contact-page container px-4">
				<div className="row">
					<span className="border mb-5 mt-5"></span>
					<div className="col-lg-6 col-md-12 contact-info">

						<h5 className="mb-3">Let's connect and create something amazing together</h5>
						<div className="info">
							<p>
								<strong>Phone</strong>
								<br />
								+45 71469765
							</p>
							<p>
								<strong>Email</strong>
								<br />
								mrjewel837@gmail.com
							</p>
						</div>
					</div>
					<div className="col-lg-6 col-md-12 contact-form">
						<form onSubmit={handleSubmit}>
							<div className="row">
								<div className="col-md-6 form-group">
									<input
										value={formData.firstName}
										type="text"
										className="form-control"
										placeholder="First Name"
										onChange={(e) => {
											setFormData(oldData => ({
												...oldData,
												"firstName": e.target.value
											}))
										}}
									/>
								</div>
								<div className="col-md-6 form-group">
									<input
										value={formData.lastName}
										type="text"
										className="form-control"
										placeholder="Last Name"
										onChange={(e) => {
											setFormData(oldData => ({
												...oldData,
												"lastName": e.target.value
											}))
										}}
									/>
								</div>
							</div>
							<div className="form-group">
								<input
									value={formData.email}
									type="email"
									className="form-control"
									placeholder="Email *"
									onChange={(e) => {
										setFormData(oldData => ({
											...oldData,
											"email": e.target.value
										}))
									}}
									required
								/>
							</div>
							<div className="form-group">
								<input
									value={formData.subject}
									type="text"
									className="form-control"
									placeholder="Subject"
									onChange={(e) => {
										setFormData(oldData => ({
											...oldData,
											"subject": e.target.value
										}))
									}}
								/>
							</div>
							<div className="form-group">
								<textarea
									value={formData.message}
									onChange={(e) => {
										setFormData(oldData => ({
											...oldData,
											"message": e.target.value
										}))
									}}
									className="form-control"
									rows={5}
									placeholder="Message"
									required
								></textarea>
							</div>
							<button type="submit" className="btn send-btn">
								Send Message
							</button>
						</form>
					</div>
				</div>
			</div>
		</>
	);
};

export default Contact;
