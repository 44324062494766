
import React from "react";
import profileImage from '../../imgs/jewel.jpg';
import "../../Components/Navbar.scss";
import Navbar from "../../Components/Navbar";
import DropdownNavbar from "../../Components/DropdownNavbar";
import { Link } from "react-router-dom";


const Home = () => {

    const [toggle, setToggle] = React.useState(true);
    const handleToggle = () => {
        setToggle(!toggle);
    };



    return (
        <>
            <DropdownNavbar onclick={handleToggle} toggle={toggle} />
            <Navbar click={handleToggle} header={"Mahamudur Rahman Jewel"} />

            <div>

                <div className="container my-5">
                    <div className="row align-items-center">
                        <div className="col-md-6 text-center">
                            <img
                                src={profileImage}
                                className="rounded-circle border border-5 border-light"
                                alt="Profile"
                                style={{ width: '80%', height: '80%' }}
                            />
                        </div>
                        <div className="col-md-6">
                            <h1 className="hello">Hello</h1>
                            <p className="know-me">Get to Know Me</p>
                            <p>
                                I'm Mahamudur, a full-stack developer with a focus on creating
                                scalable and efficient web applications that prioritize user
                                experience and functionality. Specializing in both front-end and
                                back-end development, I thrive on solving complex problems with
                                innovative solutions. I'm keen to explore new opportunities and
                                collaborate on projects that require a dynamic and skilled approach.
                            </p>
                            <div className="btn-wrapper">
                                <div className="button-group-1">

                                    <Link to={"/portfolio"}>
                                        <button className="btn btn-portfolio" type="button" style={{ color: "black" }}>Portfolio</button>
                                    </Link>
                                    <Link to={"contact"}>
                                        <button className="btn btn-contact" type="button" style={{ color: "black" }}>Contact</button>
                                    </Link>


                                </div>
                            </div>


                        </div>
                    </div>
                </div>


            </div>
        </>
    );
};

export default Home;
