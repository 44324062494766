import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Home from "./Pages/Home/home";
import Footer from "./Components/Footer";
import Portfolio from "./Pages/Portfolio/Portfoio";
import Contact from "./Pages/Contact/Contact";




const Routing = () => {
    return (
        <>
            <Router>

                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/portfolio" element={<Portfolio />} />
                    <Route path="/contact" element={<Contact />} />
                </Routes>
                <Footer />
            </Router>
        </>
    );
};

export default Routing;
