import React from "react";
import "./loader.scss";

const Loader = () => {
 return (
  <div className="container-fluid p-0 d-flex justify-content-center align-items-center vh-100 wrapper">
   <div className="loader-main">
    <h1 className="loader-text">Mahamudur Jewel</h1>
   </div>
  </div>
 );
};

export default Loader;
